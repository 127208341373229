import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6255ec9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "miss-text text-black ml-4" }
const _hoisted_4 = { class: "tooltipText" }
const _hoisted_5 = { class: "miss-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JIcon = _resolveComponent("JIcon")!
  const _component_info_circle_outlined = _resolveComponent("info-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_JIcon, {
        icon: _ctx.radioChoose ? 'mes-s' : 'mes-weixuanzhong',
        size: _ctx.$px2rem('24px'),
        "default-color": "#13C2C2",
        hoverColor: "#13C2C2"
      }, null, 8, ["icon", "size"]),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
        _createVNode(_component_a_tooltip, {
          placement: "top",
          trigger: "click"
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.showText), 1)
          ]),
          default: _withCtx(() => [
            (_ctx.iconShow)
              ? (_openBlock(), _createBlock(_component_info_circle_outlined, {
                  key: 0,
                  class: "tooltipIcon"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.value ? _ctx.value : 0), 1)
  ]))
}