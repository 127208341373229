
import { ref, defineComponent, PropType, watch } from "vue";

export default defineComponent({
    components: {},
    props: {
        //显示本界面
        show: {
            required: true,
            type: Boolean,
            default: false
        },
        //隐藏退出按钮
        hideCance: {
            required: false,
            type: Boolean,
            default: false
        },

        //标题
        title: {
            required: false,
            type: String,
        },
        //详情
        detail: {
            required: false,
            type: String,
        },
        //确认按钮名称
        btnCompleteName: {
            required: false,
            type: String,
            default: '确认'
        },
        //退出按钮名称
        btnCanceName: {
            required: false,
            type: String,
            default: '取消'
        },
        //移除mark点击操作
        maskClosable: {
            required: false,
            type: Boolean,
            default: true
        },
        //弹框关闭图标
        closable:{
            required: false,
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            visible: this.show,
            dataInfo: this.data
        }
    },
    emits: {
        //返回操作
        onResultOperate: async (status: 'cance' | 'complete') => {
            return true;
        },
    },
    //监听传入参数变化
    watch: {
        show(newValue, oldValue) {
            this.visible = newValue;
        }
    },
    setup(props: any, ctx: any) {

        //-----------------变量定义-------------------
        //let test: any = ref<string>();

        //事件触发等待框
        const loading = ref<boolean>(false);


        //------------------监听数据变化-------------

        //监听内置变更变更
        //    watch(
        //      () => props.test,
        //      (newVal: any) => {
        //      }
        //    );

        //-----------------方法定义-------------------
        //操作方法
        const onHandle = (status: string) => {
            loading.value = true;
            ctx.emit("onResultOperate", status, props.dataInfo)
            loading.value = false;
        }
        //-----------------选择导出方法或变量-------------------
        return { onHandle, loading };
    },
});
