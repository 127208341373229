/**
 * 网络请求配置
 */
import { message } from 'ant-design-vue';
import axios, { AxiosRequestConfig, Method, RawAxiosRequestHeaders } from "axios";
import JPromise from './JPromise';
import { createTask, handleTask, pushPending, removePending } from "./JRequestMeans";

/**
 * 默认的headers内容
 */
const defaultHeaders = { "Content-Type": "application/json" }
const defaultFileHeaders = { "Content-Type": "multipart/form-data" }

/**
 * 设置默认超时时间
 */
const timeout = 5000

interface Jheaders extends RawAxiosRequestHeaders {
  preventRepeat?: boolean
}

//请求封装
function request<T>(
  method: Method | string,
  url: string,
  params?: any | undefined,
  data?: any | undefined,
  headers: Jheaders = {},
  config: AxiosRequestConfig = {}
) {


  return new JPromise<T>((resolve, reject) => {
    // 请求执行前加入task
    createTask(url, reject)
    axios
      .request(
        Object.assign(
          {
            url: url,
            params: params,
            headers: headers,
            method: method,
            timeout: timeout,
            responseType: "json",
            data: data
          },
          config
        )
      )
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data)
        } else {
          // 处理task
          const datas = response.data
          datas.status = 200
          handleTask(response.config.url || "", datas)
        }
      })
      .catch((error) => {
        console.debug(error)
      })
  }).then(() => {
    console.debug('');
    
  }, (info: any) => {
    if (typeof info === 'string') {
      message.error(info.toString());
    } else if (info.msg) {
      message.error(info.msg);
    }
  })
}

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    //@ts-ignore: 防抖变量注入
    if (config.headers?.preventRepeat) {
      removePending(config) //防止多次触发请求
      config.cancelToken = new axios.CancelToken((cancelToken) => {
        pushPending(config.url ? config.method + ":" + config.url : "", cancelToken)
      })
    }
    // if (config.headers) config.headers.fuserid = "1036";
    return config
  },
  (error) => {
    return JPromise.reject(error)
  }
)

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    //@ts-ignore: 防抖变量注入
    if (response.config.headers?.preventRepeat) {
      removePending(response.config)
    }
    return response
  },
  (error) => {
    handleTask(error.config ? error.config.url : "" || "", {
      status: 500,
      success: false,
      code: "100500",
      msg: "服务器开小差了！稍后重试一下"
    })
  }
)

/**
 * 数据获取方法
 * @param url 请求url,如果包含绝对路径按绝对路径请求
 * @param params 数据
 * @param headers  header
 * @param config axios其他自定义配置
 * @param preventRepeat  是否触发防抖策略，默认true
 * @returns
 */
export function get<T>(url: string, params?: any, headers: RawAxiosRequestHeaders = {}, config: AxiosRequestConfig = {}, preventRepeat = true) {
  const aheader = Object.assign({}, defaultHeaders, headers, {
    preventRepeat: preventRepeat
  })
  return request<T>("get", url, params, {}, aheader, config)
}

/**
 * 数据新增方法
 * @param url 请求url,如果包含绝对路径按绝对路径请求
 * @param data 数据
 * @param headers  header
 * @param config axios其他自定义配置
 * @param preventRepeat  是否触发防抖策略，默认true
 * @returns
 */
export function post<T>(
  url: string,
  data?: any,
  headers: RawAxiosRequestHeaders = {},
  config: AxiosRequestConfig = {},
  preventRepeat = true,
  params: any = {}
) {
  const aheader: Jheaders = Object.assign({}, defaultHeaders, headers, {
    preventRepeat: preventRepeat
  })
  return request<T>("post", url, params, data, aheader, config)
}

/**
 * 更新方法
 * @param url 请求url,如果包含绝对路径按绝对路径请求
 * @param data 数据
 * @param headers  header
 * @param config axios其他自定义配置
 * @param preventRepeat  是否触发防抖策略，默认true
 * @returns
 */
export function put<T>(url: string, data?: any, headers: RawAxiosRequestHeaders = {}, config: AxiosRequestConfig = {}, preventRepeat = true) {
  const aheader = Object.assign({}, defaultHeaders, headers, {
    preventRepeat: preventRepeat
  })
  return request<T>("put", url, {}, data, aheader, config)
}

/**
 * 删除方法
 * @param url 请求url,如果包含绝对路径按绝对路径请求
 * @param data 数据
 * @param headers  header
 * @param config axios其他自定义配置
 * @param preventRepeat  是否触发防抖策略，默认true
 * @returns
 */
export function del<T>(url: string, data?: any, headers: RawAxiosRequestHeaders = {}, config: AxiosRequestConfig = {}, preventRepeat = true) {
  const aheader = Object.assign({}, defaultHeaders, headers, {
    preventRepeat: preventRepeat
  })
  return request<T>("delete", url, {}, data, aheader, config)
}

/**
 * 上传文件
 * @param url 请求url,如果包含绝对路径按绝对路径请求
 * @param data 文件流
 * @param headers header
 * @param config axios其他自定义配置
 * @param onUploadProgress 上传进度回调
 * @param onDownloadProgress  下载进度回调
 * @param preventRepeat 是否触发防抖策略，默认true
 * @returns
 */
export function upFiles(
  url: string,
  data: any,
  headers: RawAxiosRequestHeaders = {},
  config: AxiosRequestConfig = {},
  onUploadProgress?: (progressEvent: any) => void,
  onDownloadProgress?: (progressEvent: any) => void,
  preventRepeat = true
) {
  const aheader = Object.assign({}, defaultFileHeaders, headers, {
    preventRepeat: preventRepeat
  })

  if (onUploadProgress) {
    config.onUploadProgress = onUploadProgress
  }
  if (onDownloadProgress) {
    config.onDownloadProgress = onDownloadProgress
  }

  return request<any>("post", url, {}, data, aheader, config)
}

export { request };
export type { page };

interface page<T> {
  list: T[]
  total: number
}


