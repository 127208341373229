
import { ref, watch, defineComponent, onMounted } from "vue";
import { RightOutlined, LeftOutlined } from '@ant-design/icons-vue';

//---------------------传入参数、回调、监听定义---------------------
export default defineComponent({
    components: { RightOutlined, LeftOutlined },
    props: {
        //系数
        value: {
            required: false,
            type: String || Number,
            defalut: 1
        },
    },
    setup(props) {
        const tipsStatu = ref<boolean>(true)
        const showAll = () => {
            tipsStatu.value = false
            reset()
        }
        const showSort = () => {
            tipsStatu.value = true
        }
        const reset = () => {
            setTimeout(() => {
                tipsStatu.value = false
            }, 5000);
        }
        onMounted(() => {
            reset()
        })
        return {
            tipsStatu,
            showAll,
            showSort
        }
    }
});
