
import { defineComponent, ref, PropType, watch, onMounted } from "vue";
import JIcon from '@/components/jicon/index.vue'
export interface cellModel {
    id: number;
    status: string;
    name: string;
    operationTime: string;
    code: number;
}
export default defineComponent({
    components: { JIcon },

    props: {
        // 按钮数据列表
        buttonData: {
            required: true,
            type: Array as PropType<Array<cellModel>>,
        },
        //订单是否在生产
        orderStatu: {
            required: true,
            type: Boolean,
        },
        //是否是查看完成订单
        completeOrder: {
            required: false,
            type: Boolean,
            default: false,
        },
        orderStep: {
            required: true,
            type: Number,
        }
    },
    setup(props) {
        const timeDiffer = (startTime, endTime) => {
            startTime = startTime.replace(/-/g, "/");
            endTime = endTime.replace(/-/g, "/");
            const stime = Date.parse(startTime)
            const etime = Date.parse(endTime)
            // 两个时间戳相差的毫秒数
            const usedTime = etime - stime
            // 计算相差的天数
            const days = Math.floor(usedTime / (24 * 3600 * 1000))
            // 计算天数后剩余的毫秒数
            const leave1 = usedTime % (24 * 3600 * 1000)
            // 计算出小时数
            const hours = Math.floor(leave1 / (3600 * 1000))
            // 计算小时数后剩余的毫秒数
            const leave2 = leave1 % (3600 * 1000)
            // 计算相差分钟数
            const minutes = Math.floor(leave2 / (60 * 1000))
            // 计算分钟数后剩余的毫秒数
            const leave3 = leave2 % (60 * 1000)
            // 计算相差秒数
            const second = Math.floor(leave3 / 1000)
            return '耗时' + ((days || hours) ? (days * 24 + hours + '时') : '') + (minutes ? (minutes + '分') : '') + (second + '秒')
            // return `耗时${days * 24 + hours}时${minutes}分${second}秒`
        }
        return {
            timeDiffer,
        }

    }
});

