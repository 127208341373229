export default class JPromise<T> {
    // 1、Promise三种状态
    static PENDING = 'PENDING';
    static FULFILED = 'FULFILED';
    static REJECTED = 'REJECTED';

    private promiseStatus;
    private resolveQueues;
    private rejectQueues;
    private value: T;

    constructor(callback) {
        // 容错处理
        if (typeof callback !== 'function') {
            throw new TypeError('Promise resolver undefined is not a function')
        }

        // 初始状态
        this.promiseStatus = JPromise.PENDING;

        // 定义resolve函数队列 reject函数队列
        this.resolveQueues = [];
        this.rejectQueues = [];

        //定义初始值
        this.value;

        //调用callback函数
        callback(this._resolve.bind(this), this._reject.bind(this))
    }
    _resolve(val) {
        if (this.promiseStatus !== JPromise.PENDING) return;
        this.promiseStatus = JPromise.FULFILED;
        this.value = val;
        let handler = this.resolveQueues.pop();
        let result;
        while (handler) {
            result = handler(result ? result : this.value)
            handler = this.resolveQueues.pop()
        }
        // window.addEventListener('message', this.resolveDeal.bind(this))
        // window.postMessage('', "*")
    }

    _reject(val) {
        // 更改失败状态
        if (this.promiseStatus !== JPromise.PENDING) return;
        this.promiseStatus = JPromise.REJECTED;
        this.value = val;
         let handler = this.rejectQueues.pop();
         let result;
         while (handler) {
             result = handler(result === false ? result : this.value)
             handler = this.rejectQueues.pop()
         }
        // window.addEventListener('message', this.rejectDeal.bind(this))
        // window.postMessage('', "*")
    }

    then(resolveHandler, rejectHandler?) {
        if (resolveHandler) {
            this.resolveQueues.push(resolveHandler)
        }
        if (rejectHandler) {
            this.rejectQueues.push(rejectHandler)
        }

        return this;
    }
    catch(rejectHandler) {
        return this.then(undefined, rejectHandler)
    }
    static all(iterator) {
        const len = iterator.length;
        let n = 0;
        const vals = [];
        return new JPromise((resolve, reject) => {
            iterator.forEach((item) => {
                item.then((val) => {
                    ++n;
                    vals.push(val);
                    if (len === n) {
                        resolve(vals);
                    }

                }).catch((e) => {
                    reject(e);
                })
            })
        })
    }
    static race(iterator) {
        return new JPromise((resolve, reject) => {
            iterator.forEach((item) => {
                item.then((val) => {
                    resolve(val);
                }).catch((e) => {
                    reject(e);
                })
            })
        })
    }
    static resolve(val) {
        return new JPromise((resolve) => {
            resolve(val)
        })
    }
    static reject(val) {
        return new JPromise((resolve, reject) => {
            reject(val)
        })
    }
}

//参考代码出处 https://www.jianshu.com/p/2a0de70a841e