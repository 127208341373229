
import { defineComponent, reactive } from "vue";
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import JIcon from "@/components/jicon/index.vue";

export default defineComponent({
    components: { InfoCircleOutlined, JIcon },
    props: {
        name: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            type: Number,
        },
        iconShow: {
            required: false,
            type: Boolean,
            default: false
        },
        showText: {
            required: false,
            type: String,
        },
        radioChoose: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    emits: {
        //配置字段回调
        onToPage: () => {
            return true;
        },
    },
    setup(props: any, ctx: any) {

        const radioStyle = reactive({
            display: 'flex',
            height: '30px',
            lineHeight: '30px',
        });
        return { radioStyle }
    }
});
