import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb5a48ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timestep-content" }
const _hoisted_2 = {
  key: 0,
  class: "top"
}
const _hoisted_3 = { class: "flex items-center justify-between pr-4" }
const _hoisted_4 = { class: "time" }
const _hoisted_5 = {
  key: 1,
  class: "buttom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JIcon = _resolveComponent("JIcon")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.orderStep > 2 && _ctx.buttonData.length > 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_a_timeline, { class: "timeline" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonData, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_timeline_item, {
            color: item.status == 'hand' ? '#13C2C2' : 'gray',
            style: _normalizeStyle({
                '--timeLineBgc': item.status == 'hand' ? '#13C2C2' : '#494D56',
                '--timeLineShow': item.status == 'hand' ? ' 0 0 8px #057272' : 'none'
            }),
            key: index
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass({ text: true, activeText: item.status == 'hand' })
                }, _toDisplayString(item.name), 3),
                ((_ctx.orderStatu && item.status == 'operation') || _ctx.completeOrder)
                  ? (_openBlock(), _createBlock(_component_JIcon, {
                      key: 0,
                      icon: "mes-wancheng",
                      size: _ctx.$px2rem('32px')
                    }, null, 8, ["size"]))
                  : (_ctx.orderStatu && item.status == 'hand')
                    ? (_openBlock(), _createBlock(_component_JIcon, {
                        key: 1,
                        icon: "mes-dangqianzhishi",
                        defaultColor: "#13C2C2",
                        size: _ctx.$px2rem('32px')
                      }, null, 8, ["size"]))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("p", {
                class: _normalizeClass({ text: true, activeTime: item.status == 'hand' })
              }, _toDisplayString(item.operationTime ?
                    item.operationTime.slice(11) : '--'), 3),
              _createElementVNode("div", _hoisted_4, _toDisplayString(index != _ctx.buttonData.length - 1 ? (item.operationTime && _ctx.buttonData[index + 1].operationTime ?
                        _ctx.timeDiffer(item.operationTime, _ctx.buttonData[index + 1].operationTime) : '') : ''), 1)
            ]),
            _: 2
          }, 1032, ["color", "style"]))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.orderStep > 2 && _ctx.buttonData.length > 3 && _ctx.orderStep != (_ctx.buttonData.length || _ctx.buttonData.length - 1))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}