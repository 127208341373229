
import { defineComponent, onUnmounted, PropType, ref } from "vue";
import {
    MinusOutlined,
    PlusOutlined,
} from "@ant-design/icons-vue";
import keyBorad from "./keyBorad.vue";
import { IGetMachineTeamFindWorkingEmployeesVo } from "@/api/peopleTeam";
export default defineComponent({
    components: { keyBorad, MinusOutlined, PlusOutlined },
    props: {
        data: {
            required: true,
            type: Object as PropType<IGetMachineTeamFindWorkingEmployeesVo>,
        },
        editPerformanceBtn: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, ctx) {
        const showKeyBoard = ref<boolean>(false)
        let clickDate = ref({})
        //循环时间
        let timer = ref<number>(0);
        //循环定时器
        let loop = ref(undefined);
        //键盘收起
        const putKeyBorad = (data: any) => {
            showKeyBoard.value = false
            ctx.emit("coefficient", data)
        }
        //弹出键盘
        const showBooard = (data: any) => {
            if (props.editPerformanceBtn) {
                clickDate.value = data
                showKeyBoard.value = true
            }

        }
        const goTouch = (e, data, state: string) => {
            timer.value += 1
            loop.value = setInterval(() => {
                timer.value += 1
                if (state === "increase") {
                    data.coefficient = Number(data.coefficient) >= 100 ? 100 : Number(data.coefficient) + 1;
                } else {
                    data.coefficient = Number(data.coefficient) <= 0 ? 0 : Number(data.coefficient) - 1;
                }
                ctx.emit("coefficient", data)
                //执行长按事件
            }, 200);
            return false
        }
        const Touchmove = () => {
            restLoop()
        }
        //touchend事件
        const touchend = (e, data, state: string) => {
            restLoop()
            if (timer.value != 0) {
                //执行单次点击事件
                if (state === "increase") {
                    data.coefficient = Number(data.coefficient) >= 100 ? 100 : Number(data.coefficient) + 1;
                } else {
                    data.coefficient = Number(data.coefficient) <= 0 ? 0 : Number(data.coefficient) - 1;
                }
                ctx.emit("coefficient", data)
            }
            return false
        }
        const restLoop = () => {
            // clearTimeout(loop.value);
            clearInterval(loop.value)
        }
        onUnmounted(() => {
            // clearTimeout(loop.value);
            clearInterval(loop.value)

        })
        return {
            clickDate,
            showKeyBoard,
            putKeyBorad,
            showBooard,
            goTouch,
            touchend,
            Touchmove
        }
    }
});
