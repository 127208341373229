
import { defineComponent, ref } from "vue";
import { createFromIconfontCN } from "@ant-design/icons-vue";

const IconFont = createFromIconfontCN({
  scriptUrl: "https://djcps.oss-cn-hangzhou.aliyuncs.com/pro/djmes/font/iconfont.js",
});
const __default__ = defineComponent({
  components: {
    IconFont,
  },
  props: {
    icon: {
      required: false,
      type: String,
    },
    defaultColor: {
      required: false,
      type: String,
      default: "#909399",
    },
    hoverColor: {
      required: false,
      type: String,
      default: "#13C2C2",
    },
    size: {
      required: false,
      type: String,
      default: "13px",
    },
  },
  emits: {},
  setup(props: any, ctx: any) {
    const color = ref(props.defaultColor);
    const fontSize = ref(props.size);

    //进入修改图标
    const into = () => {
      color.value = props.hoverColor;
    };

    //离开恢复图标
    const leave = () => {
      color.value = props.defaultColor;
    };

    return {
      color,
      into,
      leave,
      fontSize,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "8374fdf2": (_ctx.color),
  "2f74c9dd": (_ctx.size)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__