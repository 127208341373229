// 侧边菜单
import { RouteRecordRaw } from "vue-router";

export type menuState = {
  menuRouter: RouteRecordRaw[];
  menuId: number;
};

const state: menuState = {
  // 侧边菜单
  menuRouter: [],

  // 顶层id(栏目)
  menuId: 1,
};

const menu = {
  namespaced: true,

  state,

  mutations: {
    setMenu(state: menuState, router: RouteRecordRaw[]) {
      state.menuRouter = router;
    },

    setId(state: menuState, id: number) {
      state.menuId = id;
    },
  },
};

export default menu;
