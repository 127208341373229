
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue';
import API, { ICheckCodeBo } from "@/api/login/index";
import { message } from "ant-design-vue";
export default defineComponent({
    name: 'login',
    emits: ["ckickData"],
    setup(props, ctx) {
        const formState = reactive<ICheckCodeBo>({
            phone: "",
            verificationCode: "",
        });
        const { proxy } = getCurrentInstance()
        const checkePhonenumber = function (rule, value, callback) {
            if (value) {
                const reg = /^1[345789]\d{9}$/
                if (!reg.test(value)) {
                    return Promise.reject("手机号格式不正确")
                }
                else {
                    return Promise.resolve();
                }
            }
            else {
                return Promise.reject("请输入手机号")
            }

        }
        const rules = {
            phone: [
                {
                    required: true,
                    trigger: 'blur',
                    validator: checkePhonenumber,
                },
            ],
            verificationCode: [
                { required: true, message: '请输入验证码', },
            ],

        }
        const onFinish = (values: any) => {
            API.checkCode(formState).then((res: any) => {
                ctx.emit('ckickData', { name: 'setPass', code: res.modifyTicket })
            })
        }
        const cancle = () => {
            ctx.emit('ckickData', { name: 'login', code: '' })
        }
        const time = ref(60);
        const isDisposed = ref(false);
        const getCode = () => {
            if (!formState.phone) {
                (proxy as any).$refs.formRef.validateFields('phone', valid => {
                    return
                })
            } else {
                API.sendCode({ phone: formState.phone }).then((res) => {
                    message.success({
                        content: "验证码发送成功",
                    });
                    isDisposed.value = true;
                    handleTimeChange();
                })
            }
        }
        const handleTimeChange = () => {
            if (time.value <= 0) {
                isDisposed.value = false;
                time.value = 60;
            } else {
                setTimeout(() => {
                    time.value--;
                    handleTimeChange();
                }, 1000);
            }
        };
        return {
            formState,
            isDisposed,
            time,
            rules,
            onFinish,
            cancle,
            getCode,
            checkePhonenumber,
        };
    },
});

