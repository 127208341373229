import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dbed94c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "peopleName" }
const _hoisted_2 = { class: "flex-1 flex items-center col-text" }
const _hoisted_3 = { class: "w-3/5 employeesCode-ellipsis text-center" }
const _hoisted_4 = { class: "w-2/5 position-ellipsis text-center" }
const _hoisted_5 = { class: "text-right numPrecent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_checkbox, {
        disabled: _ctx.data.isWorking == 'y',
        checked: _ctx.data.checked,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick($event, _ctx.data)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data.employeesNmae), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data.code), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.position), 1)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.coefficient && Number(_ctx.data.coefficient) > 0 ? _ctx.data.coefficient + '%' : 0 + "%"), 1)
        ]),
        _: 1
      }, 8, ["disabled", "checked"])
    ]),
    _: 1
  }))
}