

import { defineComponent, ref, computed, onMounted, watch, onBeforeUnmount } from "vue";
import API from "@/api/peopleTeam/index.api"
import mittBus from '../constant/Bus'
import JOnWorkCheck from "../components/JOnWorkCheck.vue";
import JOnwork from "../components/JOnwork.vue";
import { IGetMachineTeamFindWorkingEmployeesVo } from "@/api/peopleTeam";
export default defineComponent({
    components: { JOnWorkCheck, JOnwork },
    props: {
        // 下机状态
        offWorkBtn: {
            type: Boolean,
            default: false
        },
        //修改系数状态
        editPerformanceBtn: {
            type: Boolean,
            default: false
        },
        // 上机状态
        onWorkBtn: {
            type: Boolean,
            default: false
        },
    },
    setup(props, ctx) {
        //计算人员系数和
        const totalCoefficient = computed(() => {
            const num = ref(0)
            plainOptions.value.map(item => {
                num.value = Number(num.value) + Number(item.coefficient)
            })
            return num
        })
        const checked = ref(false)
        //上机人员列表
        const plainOptions = ref<IGetMachineTeamFindWorkingEmployeesVo[]>([])
        //上机人员下机选择列表
        const offWorkChecked = ref<IGetMachineTeamFindWorkingEmployeesVo[]>([])
        const coefficient = (data) => {
            ctx.emit("factorChange", data)
        }
        //上机人员下机全选
        const handleCheckAllChange = (e) => {
            if (e.target.checked) {
                offWorkChecked.value = plainOptions.value
            } else {
                offWorkChecked.value = []
            }
        }
        // 获取上机列表
        const getOnWorkList = () => {
            API.getMachineTeamFindWorkingEmployees().then(res => {
                plainOptions.value = res.list
            })
        }
        watch(
            () => offWorkChecked,
            val => {
                checked.value = offWorkChecked.value.length === plainOptions.value.length ? true : false
                mittBus.emit('offWorkChoose', val.value)
                ctx.emit('offWorkChooseList', offWorkChecked.value)
            }, {
            deep: true
        });
        watch(() => props.editPerformanceBtn, val => {
            if (!val) getOnWorkList()
        })
        onMounted(async () => {
            getOnWorkList()
            //上机人员下机选择取消
            mittBus.on('offWorkCancle', (data: any) => {
                offWorkChecked.value = data
            })
        })
        // onBeforeUnmount(() => {
        //     // 移除事件
        //     // mittBus.off("onWorkChoose")
        //     mittBus.all.clear();
        // })
        return {
            totalCoefficient,
            plainOptions,
            offWorkChecked,
            checked,
            handleCheckAllChange,
            getOnWorkList,
            coefficient
        }
    }
})

