import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_radio_group, {
      value: _ctx.chooseTags,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chooseTags) = $event)),
      buttonStyle: "solid",
      size: "large",
      onChange: _ctx.switchRadio
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsData, (item) => {
          return (_openBlock(), _createBlock(_component_a_radio_button, {
            class: _normalizeClass(["tagsButton", [
        _ctx.tFontSize ? _ctx.tFontSize : '',
        _ctx.tTextAlign ? _ctx.tTextAlign : '',
        _ctx.tRtextAlign ? _ctx.tRtextAlign : '',
      ]]),
            value: item.value,
            key: item.value,
            style: _normalizeStyle({ width:  _ctx.$px2rem(_ctx.width), height: _ctx.$px2rem('48px'), fontSize: _ctx.$px2rem('24px'), lineHeight: _ctx.$px2rem('48px'), })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["class", "value", "style"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"])
  ]))
}