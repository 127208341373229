import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0ed76da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "palnlist",
  class: "relative flex flex-grow-1 flex-1 text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      class: "absolute h-full w-full ant-table-striped",
      columns: _ctx.columns,
      "data-source": _ctx.data?.list,
      pagination: _ctx.paginationPage,
      onChange: _ctx.pageChange,
      scrollToFirstRowOnChange: "",
      scroll: { x: _ctx.scrollX, y: _ctx.scrollY<=130?130:_ctx.scrollY },
      "row-selection": _ctx.rowSelection
    }, {
      headerCell: _withCtx(({ title, column }) => [
        _renderSlot(_ctx.$slots, "header", {
          column: column,
          title: title
        }, undefined, true)
      ]),
      bodyCell: _withCtx(({ column, record, index }) => [
        _renderSlot(_ctx.$slots, "cell", {
          column: column,
          record: record,
          index: index
        }, undefined, true)
      ]),
      _: 3
    }, 8, ["columns", "data-source", "pagination", "onChange", "scroll", "row-selection"]),
    _createVNode(_component_a_space, { class: "my-4 absolute top-0 left-0" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "butlist", {}, undefined, true)
      ]),
      _: 3
    })
  ], 512))
}