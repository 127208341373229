
import { defineComponent, ref } from "vue";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';
export default defineComponent({
    components: { PlusOutlined, MinusOutlined },
    props: {
        name: {
            required: true,
            type: String,
        },
        //修改数据
        value: {
            required: false,
            type: Number,
        },
        //仅作展示数据用
        showValue: {
            require: true,
            type: Number
        },
        //展示数据最小值
        minShowValue: {
            require: false,
            type: Number,
        },
        //最大值
        maxValue: {
            required: false,
            type: Number,
        },
        minValue: {
            required: false,
            type: Number,
        },
        //标记 用于修改之后返回的标记
        sign: {
            required: true,
            type: String,
        },
        //是否可以修改 true表示不可修改
        disable: {
            required: false,
            type: Boolean,
            default: true,
        },
        //是否允许为空
        allowNegative: {
            required: false,
            type: Boolean,
            default: true,
        },
        explain: {
            required: false,
            type: String,
        }
    },
    emits: {
        //配置字段回调
        onChangeValue: (sign: string, newValue: number) => {
            return true;
        },
    },
    watch: {
        value(newName, oldName) {
            this.count = newName
        },
    },
    setup(props: any, ctx: any) {
        //获取当前选中的key
        const count = ref(props.value);
        //循环时间
        let loopTime = ref<number>(0);
        let timer = ref<number>(0);
        //循环定时器
        let loop = ref(undefined);
        const goTouch = (e, state: string) => {
            timer.value += 1
            loop.value = setInterval(() => {
                timer.value += 1
                if (state === "increase") {
                    count.value = Number(count.value) + 10;
                    //不能大于等于某个值
                    if (props.maxValue || props.maxValue === 0) {
                        if (count.value >= props.maxValue) {
                            count.value = props.maxValue
                        }
                    }
                } else {
                    if (props.showValue <= props.minShowValue) {
                        return
                    }
                    // count.value = Number(count.value) <= 0 ? 0 : Number(count.value) - 10 <= 0 ? Number(count.value) - 1 : Number(count.value) - 10;
                    count.value = Number(count.value) - 10 <= 0 ? Number(count.value) - 1 : Number(count.value) - 10;
                    //不允许为负数
                    //     if (!(props.allowNegative || count.value > 0)) {
                    //         count.value = 0
                    //     }
                    //不能小于等于某个值
                    if (props.minValue) {
                        if (count.value <= props.minValue) {
                            count.value = props.minValue
                        }
                    }
                }
                ctx.emit('onChangeValue', props.sign, Number(count.value))
                //执行长按事件
            }, 500);
            return false
        }
        //touchend事件
        const outTouch = (e, state: string) => {
            restLoop()
            if (timer.value != 0) {
                //执行单次点击事件
                if (state === "increase") {
                    count.value = Number(count.value) + 1;
                    //不能大于等于某个值
                    if (props.maxValue || props.maxValue === 0) {
                        if (count.value >= props.maxValue) {
                            count.value = props.maxValue
                        }
                    }
                } else {
                    if (props.showValue <= props.minShowValue) {
                        return
                    }
                    console.log(props.showValue, props.minShowValue, 'props.minShowValue');
                    count.value = Number(count.value) - 1;
                    //不能小于等于某个值
                    if (props.minValue) {
                        if (count.value <= props.minValue) {
                            count.value = props.minValue
                        }
                    }
                }
                ctx.emit('onChangeValue', props.sign, Number(count.value))
            }
            return false
        }
        const restLoop = () => {
            // clearTimeout(loop.value);
            clearInterval(loop.value)
        }
        const Touchmove = () => {
            restLoop()
        }
        return {
            count,
            outTouch,
            restLoop,
            Touchmove,
            goTouch,
        };
    },
});
