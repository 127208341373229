import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61a654b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "keycontent" }
const _hoisted_2 = { id: "keybord" }
const _hoisted_3 = { class: "keyBox" }
const _hoisted_4 = { class: "keyBox" }
const _hoisted_5 = { class: "keyBox" }
const _hoisted_6 = { class: "keyBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "1",
          "data-code": "49",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickNum(1)))
        }, "1 "),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "2",
          "data-code": "50",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickNum(2)))
        }, "2"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "3",
          "data-code": "51",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickNum(3)))
        }, "3")
      ]),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "4",
          "data-code": "52",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickNum(4)))
        }, "4"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "5",
          "data-code": "53",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickNum(5)))
        }, "5"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "6",
          "data-code": "54",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clickNum(6)))
        }, "6")
      ]),
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "7",
          "data-code": "55",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clickNum(7)))
        }, "7"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "8",
          "data-code": "56",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clickNum(8)))
        }, "8"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "9",
          "data-code": "57",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clickNum(9)))
        }, "9")
      ]),
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": ".",
          "data-code": "110",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clickNum(0)))
        }, " 0"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "0",
          "data-code": "48",
          style: {"color":"#606266"},
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.offKeyBoard && _ctx.offKeyBoard(...args)))
        }, " 确认"),
        _createElementVNode("li", {
          class: "keyStyle",
          "data-key": "delete",
          "data-code": "46",
          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.delte && _ctx.delte(...args))),
          style: {"color":"#606266"}
        }, "删除")
      ])
    ]),
    _createElementVNode("div", {
      class: "keyMask",
      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.offKeyBoard && _ctx.offKeyBoard(...args)))
    })
  ]))
}