import { createApp } from "vue";
import app from "./App.vue";
import antd from "ant-design-vue";
import router from "./router";
import store from "./store";
import "tailwindcss/tailwind.css"
import '@/assets/theme-default.less';
import 'amfe-flexible';
import '@/utils/rem';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// 对vue进行类型补充说明
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $px2rem: any
    }
}
function px2rem(px) {
    if (/%/ig.test(px)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
        return px
    } else {
        return (parseFloat(px) / 16) + 'rem'
    }
}
const rootApp = createApp(app);
rootApp.config.globalProperties.$px2rem = px2rem // 放到全局
rootApp.use(store);
rootApp.use(router);
rootApp.use(antd);
rootApp.use(Viewer, {
    defaultOptions: {
        // 自定义默认配置
        zIndex: 3000,
        navbar: true, //底部缩略图
        title: false,
        toolbar: 4,
        fullscreen: true,
        scalable:false

    }
});

rootApp.mount("#app");





