
import chooseTeam from "./page/chooseTeam.vue";
import chooseEquipment from "./page/chooseEquipment.vue";
import { defineComponent, ref, watch } from "vue";
import router from "@/router/index";

export default defineComponent({
  components: { chooseTeam, chooseEquipment },
  setup(props, ctx) {
    const chooseEquipmentStatu = ref(true)
    const chooseClick = (data) => {
      chooseEquipmentStatu.value = false
    }
    const cancelClick = () => {
      chooseEquipmentStatu.value = false
    }
    const backClick = () => {
      chooseEquipmentStatu.value = true

    }
    watch(
      () => router, (val) => {
        if (val.currentRoute.value.query && val.currentRoute.value.query.key == '2') {
          chooseEquipmentStatu.value = false
        }
      },
      { immediate: true }
    )
    return {
      chooseEquipmentStatu,
      chooseClick,
      cancelClick,
      backClick,
    }
  }
})


