import { get, put, post, del, page } from "@/api/service";
import * as bo from "./index.type.bo";
import * as vo from "./index.type.vo";

const baseUrl = "djmes/messerver";


export default {
  /**
   * 机台端查询获取班组所有人员-/machine/team/getTeamAndUser
   * @returns 
   */
  getMachineTeamGetTeamAndUser() {
    return get<page<vo.IGetMachineTeamGetTeamAndUserVo>>(baseUrl + "/machine/team/getTeamAndUser");
  },
  /**
   * 机台端查询该机台上机人员-/machine/team/findWorkingEmployees
   * @returns 
   */
  getMachineTeamFindWorkingEmployees() {
    return get<page<vo.IGetMachineTeamFindWorkingEmployeesVo>>(baseUrl + "/machine/team/findWorkingEmployees");
  },
  /**
   * 机台端人员系数修改-/machine/team/adjustedEmployeesCoefficient
   * @returns 
   */
  postMachineTeamAdjustedEmployeesCoefficient(bo: string[]) {
    return post(baseUrl + "/machine/team/adjustedEmployeesCoefficient", bo);
  },
  /**
   * 机台端人员下机-/machine/team/offMachine
   * @returns 
   */
  postMachineTeamOffMachine(bo: string[]) {
    return post(baseUrl + "/machine/team/offMachine", bo);
  },
  /**
   * 机台端人员上机-/machine/team/onMachine
   *  * @param bo 传入参数
   * @returns 
   */
  postMachineTeamOnMachine(bo: string[]) {
    return post(baseUrl + "/machine/team/onMachine", bo);
  },
  /**
   * 机台端选择班组页获取简易班组成员消息-/machine/team/getSimpleTeamUserList
   * @returns 
   */
  getMachineTeamGetSimpleTeamUserList(bo: bo.IPostMachineTeamBindMachineBo) {
    return get<page<vo.IGetMachineTeamGetSimpleTeamUserListVo>>(baseUrl + "/machine/team/getSimpleTeamUserList", bo);
  },
  /**
   * 机台端选择班组绑定-/machine/team/bindTeam
   * @param bo 传入参数
   * @returns 
   */
  postMachineTeamBindTeam(bo: bo.IPostMachineTeamBindTeamBo) {
    return post(baseUrl + "/machine/team/bindTeam", bo);
  },
  /**
   * 获取机台列表-/machine/team/getMachineBindList
   * @returns 
   */
  getMachineTeamGetMachineBindList() {
    return get<page<vo.IGetMachineTeamGetMachineBindListVo>>(baseUrl + "/machine/team/getMachineBindList");
  },
  /**
   * 绑定机台和操作人员关系-/machine/team/bindMachine
   * @param bo 传入参数
   * @returns 
   */
  // postMachineTeamBindMachine(bo: bo.IPostMachineTeamBindMachineBo) {
  //   return post(baseUrl + "/machine/team/bindMachine", bo);
  // },
};