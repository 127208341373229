
import { defineComponent, ref, toRefs } from "vue";
import {
    MinusOutlined,
    PlusOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

//定义传入变量
export default defineComponent({
    props: {
        value: {
            required: false,
            type: String,
            default: '',
        },
        editData: {
            required: true,
            type: Object,
            default: () => ({})
        }
    },
    components: { MinusOutlined, PlusOutlined },
    setup(props, ctx) {
        //循环时间
        let timer = ref<number>(0);
        //循环定时器
        let loop = ref(undefined);
        const myData = toRefs(props.editData)
        const delte = () => {
            if (myData.coefficient.value != '') {
                const strArr = myData.coefficient.value.toString().split('')
                if (strArr.length < 1) {
                    myData.coefficient.value = ''
                } else {
                    strArr.pop();
                    myData.coefficient.value = Number(strArr.join(''))
                }
            }
        }
        const offKeyBoard = () => {
            restLoop()
            ctx.emit('putKeyBorad', props.editData)
        }
        const clickNum = (data: any) => {
            const strArr = myData.coefficient.value ? myData.coefficient.value.toString().split('') : []
            strArr.push(data);
            if (Number(strArr.join('')) > 100) {
                message.error('员工系数不能超过100%');
            } else {
                myData.coefficient.value = Number(strArr.join(''))
            }
        }
        const goTouch = (e, state: string) => {
            timer.value += 1
            loop.value = setInterval(() => {
                timer.value += 1
                if (state === "increase") {
                    myData.coefficient.value = Number(myData.coefficient.value) >= 100 ? 100 : Number(myData.coefficient.value) + 1;
                } else {
                    myData.coefficient.value = Number(myData.coefficient.value) <= 0 ? 0 : Number(myData.coefficient.value) - 1;
                }
                //执行长按事件
            }, 200);
            return false
        }
        
        //touchend事件
        const outTouch = (e, state: string) => {
            restLoop()
            if (timer.value != 0) {
                //执行单次点击事件
                if (state === "increase") {
                    myData.coefficient.value = Number(myData.coefficient.value) >= 100 ? 100 : Number(myData.coefficient.value) + 1;
                } else {
                    myData.coefficient.value = Number(myData.coefficient.value) <= 0 ? 0 : Number(myData.coefficient.value) - 1;
                }
            }
            return false
        }
        const restLoop = () => {
            // clearTimeout(loop.value);
            clearInterval(loop.value)

        }
        const Touchmove = () => {
            restLoop()
        }
        return {
            delte,
            myData,
            offKeyBoard,
            clickNum,
            outTouch,
            goTouch,
            Touchmove
        }
    }
})

