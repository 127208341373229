import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b585c4c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex item-center justify-between w-full overflow-hidden" }
const _hoisted_2 = { class: "peopleName" }
const _hoisted_3 = { class: "flex-1 flex items-center col-text" }
const _hoisted_4 = { class: "w-3/5 employeesCode-ellipsis text-center" }
const _hoisted_5 = { class: "w-2/5 position-ellipsis text-center" }
const _hoisted_6 = { class: "achievements" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_keyBorad = _resolveComponent("keyBorad")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.employeesName), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.employeesCode), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.position), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.editPerformanceBtn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onTouchstart: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goTouch($event, _ctx.data, 'reduce')), ["prevent"])),
              onTouchmove: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              onTouchend: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.touchend($event, _ctx.data, 'reduce')), ["prevent"])),
              class: "Symbol font-semibold text-center",
              style: _normalizeStyle({ marginRight: _ctx.$px2rem('24px') })
            }, [
              _createVNode(_component_minus_outlined)
            ], 36))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "text-center",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showBooard(_ctx.data))),
          style: _normalizeStyle({ minWidth: _ctx.$px2rem('70px') })
        }, _toDisplayString(_ctx.data.coefficient && Number(_ctx.data.coefficient) > 0 ? _ctx.data.coefficient + '%' : 0 + '%'), 5),
        (_ctx.editPerformanceBtn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onTouchstart: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.goTouch($event, _ctx.data, 'increase')), ["prevent"])),
              onTouchend: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.touchend($event, _ctx.data, 'increase')), ["prevent"])),
              onTouchmove: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              class: "Symbol font-semibold text-center",
              style: _normalizeStyle({ marginLeft: _ctx.$px2rem('24px') })
            }, [
              _createVNode(_component_plus_outlined)
            ], 36))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showKeyBoard)
      ? (_openBlock(), _createBlock(_component_keyBorad, {
          key: 0,
          onPutKeyBorad: _ctx.putKeyBorad,
          editData: _ctx.clickDate
        }, null, 8, ["onPutKeyBorad", "editData"]))
      : _createCommentVNode("", true)
  ], 64))
}