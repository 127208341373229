
// 设备判断
function remSize() {
    let winWidth;
    // 获取窗口宽度
    if (window.innerWidth) {
        winWidth = window.innerWidth;
    } else if ((document.body) && (document.body.clientWidth)) {
        winWidth = document.body.clientWidth;
    }
    // 通过深入Document内部对body进行检测，获取窗口大小
    if (
        document.documentElement
        && document.documentElement.clientHeight
        && document.documentElement.clientWidth
    ) {
        winWidth = document.documentElement.clientWidth;
    }
    // 修改font-size
    if (winWidth <= 700) {
        winWidth = 700
    }
    const fontSize = ((winWidth / 1920) * 16).toFixed(4); //375设计稿宽度，16同css.loaderOptions.postcss.postcssOptions.plugins中postcss-pxtorem插件的配置项rootValue值
    document.documentElement.style.fontSize = `${fontSize}px`;
}
remSize()
window.onresize = function () {
    remSize()
}

