
import { defineComponent, ref, toRefs } from "vue";
import { message } from "ant-design-vue";

//定义传入变量
export default defineComponent({
  props: {
    value: {
      required: false,
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const editDate: any = ref(props.value ? props.value.toString().split('') : [])
    const delte = () => {
      if (editDate.value.length > 0) {
        editDate.value.pop()
        ctx.emit('putKeyBorad', editDate.value.join(''))
      }
    }
    const offKeyBoard = () => {
      ctx.emit('putKeyBorad', 'off')
    }
    const clickNum = (data: number) => {
      editDate.value.push(String(data))
      ctx.emit('putKeyBorad', editDate.value.join(''))
    }
    return {
      delte,
      offKeyBoard,
      clickNum,
    }
  }
})

