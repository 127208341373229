import { get, put, post, del, page } from "@/api/service";
import * as bo from "./index.type.bo";

const baseUrl = "";
export default {
  appLogin(data: bo.ILoginAppParmsBo) {
    return post(
      baseUrl + "/djauth/cas/applogin.do",
      data,
    );
  },
  login(data: bo.ILoginParmsBo) {
    return post(
      baseUrl + "/djauth/new/weblogin.do",
      {},
      {
        "content-type": "x-www-form-urlencoded",
      },
      {},
      true,
      data
    );
  },
  //发送验证码
  sendCode(data: bo.ISendCodeBo) {
    return post(baseUrl + "/djauth/cas/sendFindCode.do", data);
  },
  //校验验证码
  checkCode(data: bo.ICheckCodeBo) {
    return post(baseUrl + "/djauth/cas/checkFindCode.do", data);
  },
  //忘记密码
  modPwd(data: bo.IModifyTicketBo) {
    return post(
      baseUrl + "/djauth/cas/modifyPasswordByVerificationCode.do",
      data
    );
  },
  //修改密码
  editPassword(data :bo.IModifyEditPassTicketBo) {
    return post(baseUrl + "/djauth/cas/editPassword.do", data);
  },
  //退出登陆
  logout() {
    return post(baseUrl + "/djauth/cas/logout.do", { appName: "DJMES" });
  },
 
};

