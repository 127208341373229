//例子
// export const exm:string="test";
export const storage = {
    //存储 key(machineId:选择的机台数据，teamId：选择的班组数据 )
    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    //取出班组数据
    get<T>(key: string) {
        const value = localStorage.getItem(key);
        if (value && value != "undefined" && value != "null") {
            return <T>JSON.parse(value);
        }
    },
    // 删除数据
    remove(key: string) {
        localStorage.removeItem(key);
    },
};

