
import { IGetMachineTeamFindWorkingEmployeesVo } from "@/api/peopleTeam";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    components: {},
    props: {
        data: {
            required: true,
            type: Object as PropType<IGetMachineTeamFindWorkingEmployeesVo>,
        },
    },
});
