export type baseInforState = {
  //计划生产数
  planOutput: number,
  //记录上报时候的正品数
  recordGoodNum: number,
  //是否支持调机
  isDebugGood: boolean,
  //调机数
  debugOutputAdjustment: number,
  //调机正品数
  debugGoodOutputAdjustment: number,
}

export type keepInforState = {
  inforList: string[],
  wastList: string[],
  combineProcedureList: string[],
  baseInfor: baseInforState,
  statue: boolean,
}

const state: keepInforState = {
  // 需缓存路由名称
  inforList: [],
  wastList: [],
  baseInfor: {
    planOutput: 0,
    recordGoodNum: 0,
    isDebugGood: false,
    debugOutputAdjustment: 0,
    debugGoodOutputAdjustment: 0,
  },
  combineProcedureList: null,
  statue: false
}
const keepInforReport = {
  namespaced: true,
  state,
  mutations: {
    setKeepInforList(state, infor: keepInforState) {
      const { inforList, wastList, baseInfor, statue, } = infor
      state.inforList = inforList
      state.wastList = wastList
      state.baseInfor = baseInfor
      state.statue = statue
    },
    setCombineProcedureList(state, infor) {
      const { combineProcedureList } = infor
      state.combineProcedureList = combineProcedureList
    },
    removeCombineProcedureList(state) {
      state.combineProcedureList = null
    },
    removeKeepInforStatu(state, infor) {
      state.inforList = [],
        state.wastList = [],
        state.statue = false
      state.baseInfor = {}
    }
  },
  actions: {}
}

export default keepInforReport