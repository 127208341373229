import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1d7b772"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home w-1/1 h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_phoneCode = _resolveComponent("phoneCode")!
  const _component_ForgetPass = _resolveComponent("ForgetPass")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.loginState == 'login')
      ? (_openBlock(), _createBlock(_component_LoginForm, {
          key: 0,
          onCkickData: _ctx.ckickData
        }, null, 8, ["onCkickData"]))
      : _createCommentVNode("", true),
    (_ctx.state.loginState == 'code')
      ? (_openBlock(), _createBlock(_component_phoneCode, {
          key: 1,
          onCkickData: _ctx.ckickData
        }, null, 8, ["onCkickData"]))
      : _createCommentVNode("", true),
    (_ctx.state.loginState == 'setPass')
      ? (_openBlock(), _createBlock(_component_ForgetPass, {
          key: 2,
          code: _ctx.state.code,
          onCkickData: _ctx.ckickData
        }, null, 8, ["code", "onCkickData"]))
      : _createCommentVNode("", true)
  ]))
}