/**
 * 节流函数 在规定的时间内请求函数的次数,只有首次提交有效
 * @param fn  需要执行的方法
 * @param interval 执行的间隔时间
 */
export const  throttle=(fn, interval)=> {
    let last = 0
    let timer
    const interVal = interval || 200
    return function(...args) {
      const now = new Date().getTime()
      if (last && now - last < interVal) {
        clearTimeout(timer)
        timer = setTimeout(function() {
          last = new Date().getTime()
          // fn.apply(th, args)
        }, interVal)
      } else {
        last = new Date().getTime()
        fn.apply(this, args)
      }
    }
  }
/**
* @desc 函数防抖
*/
export const debounce= (fn, delay = 300)=>{
    let timer = null
    return function (...args) {
        if(timer != null){
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(()=>{
            fn.call(this, ...args)
        }, delay);
    }
}