
import { ref, defineComponent, PropType, watch, onMounted } from "vue";
import { string } from "vue-types";

export interface ITagsData {
  //标签名称
  name: string;
  //标志值
  value: string;
}

export default defineComponent({
  components: {},
  props: {
    //默认选中的标签值
    defaultTag: {
      required: false,
      type: String,
    },
    //标签数据
    tagsData: {
      required: true,
      type: Array as PropType<ITagsData[]>,
    },

    //选中值
    value: {
      required: false,
      type: String,
    },

    //文字大小
    tFontSize: {
      required: false,
      type: String,
    },
    //文字对齐方式
    tTextAlign: {
      required: false,
      type: String,
    },
    //圆角
    tRtextAlign: {
      required: false,
      type: String,
    },
    //宽度
    width: {
      required: false,
      type: string,
      default: "180px",
    },

    //是否允许在已经选中的情况下，取消选中
    canceTags: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  //   },
  emits: {
    switchTags: (page: ITagsData) => {
      return true;
    },
  },
  watch: {
    value(newName, oldName) {
      this.chooseTags = newName
    },
  },
  setup(props: any, ctx: any) {
    //-----------------变量定义-------------------
    const chooseTags = ref(props.defaultTag);

    const curChooseTags = ref(props.defaultTag);
    //------------------监听数据变化-------------

    //监听菜单信息变更
    // watch(
    //   () => props.value,
    //   (newVal: any) => {
    //     chooseTags.value = newVal;
    //   }
    // );


    //-----------------方法定义-------------------
    //const testMethon = function (item:string): string {
    //  return "";
    //};
    const switchRadio = () => {
      for (let index = 0; index < props.tagsData.length; index++) {
        const element = props.tagsData[index];
        if (chooseTags.value === element.value) {
          if (curChooseTags.value === chooseTags.value && props.canceTags) {
            chooseTags.value = { name: "", value: "" };
            curChooseTags.value = "";
          }
          curChooseTags.value = chooseTags.value;
          //切换页面按钮
          ctx.emit("switchTags", chooseTags);
          break;
        }
      }
    };

    //-----------------选择导出方法或变量-------------------
    return { chooseTags, switchRadio,  };
  },
});
