
import { defineComponent, PropType, watch, toRefs, ref } from "vue";
import {
    MinusOutlined,
    PlusOutlined,
} from "@ant-design/icons-vue";
import JIcon from '@/components/jicon/index.vue'
export interface IItemData {
    text: string;
    num: string | number
}
//定义传入变量
export default defineComponent({
    props: {
        value: {
            required: false,
            type: Object as PropType<IItemData>,

        },
        minValue: {
            required: false,
            type: Number,
        }
    },
    components: { MinusOutlined, PlusOutlined, JIcon },
    emits: {
        //配置字段回调
        putKeyBorad: (value: number) => {
            return true;
        },
    },
    setup(props, ctx) {
        let numberValue = ref<number>(0)
        watch(() => props.value, (newVal, oldVal) => {
            if (newVal) {
                numberValue.value = JSON.parse(JSON.stringify(newVal)).num
            }
        }, {
            deep: true,
            immediate:true,
        })
        const reduce = (data: number) => {
            numberValue.value = (numberValue.value - data) < 0 ? 0 : numberValue.value - data;
            if (props.minValue) {
                if (numberValue.value <= props.minValue) {
                    numberValue.value = Number(props.minValue)
                }
            }
            offKeyBoard()
        }
        const add = (data: number) => {
            numberValue.value = Number(numberValue.value) + data;
            offKeyBoard()
        }
        const delte = () => {
            let inValue = numberValue.value + '';
            if (inValue.length > 1) {
                numberValue.value = Number(inValue.substring(0, inValue.length - 1)) ? Number(inValue.substring(0, inValue.length - 1)) : 0;
            } else {
                numberValue.value = 0
            }
            offKeyBoard()
        }
        const offKeyBoard = () => {
            ctx.emit('putKeyBorad', Number(numberValue.value))
        }
        const clickNum = (data: number) => {
            numberValue.value = Number(numberValue.value + '' + data);
            offKeyBoard()
        }
        return {
            reduce,
            add,
            delte,
            clickNum,
            numberValue
        }
    }
})

