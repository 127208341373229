
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        // 按钮名称
        name: {
            required: true,
            type: String,
        },
        //按钮类型
        value: {
            required: false,
            type: String,
        },
        //背景颜色
        bgColor: {
            required: false,
            type: String,
            default: "#13C2C2"
        },
        //文字颜色
        textColor: {
            required: false,
            type: String,
            default: "#ffffff"
        },
        //左边距
        marginRight: {
            required: false,
            type: Number,
            default: 24
        },
        disable: {
            required: false,
            type: Boolean,
            default: false
        }
    },
});
