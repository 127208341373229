
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        name: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            type: Number,
        },
    },
});
