import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf307ac8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text flex items-center" }
const _hoisted_2 = { class: "tipsIcon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_left_outlined = _resolveComponent("left-outlined")!
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createBlock(_component_a_alert, {
    type: "warning",
    "show-icon": _ctx.tipsStatu
  }, {
    message: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.tipsStatu ? '当前单品出数为' + _ctx.value : _ctx.value), 1),
        _createElementVNode("span", _hoisted_2, [
          (_ctx.tipsStatu)
            ? (_openBlock(), _createBlock(_component_right_outlined, {
                key: 0,
                onClick: _ctx.showAll
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_left_outlined, {
                key: 1,
                onClick: _ctx.showSort
              }, null, 8, ["onClick"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["show-icon"]))
}