import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d43ebcd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex w-full h-full flex-col p-8" }
const _hoisted_2 = {
  key: 0,
  class: "text-3xl text-black"
}
const _hoisted_3 = {
  key: 1,
  class: "text-2xl text-black"
}
const _hoisted_4 = {
  class: "flex",
  style: {"border-top":"1px solid #DCDCDC"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    bodyStyle: { padding: 0 },
    onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onHandle('close'))),
    width: _ctx.$px2rem(500),
    closable: _ctx.closable,
    footer: null,
    maskClosable: _ctx.maskClosable,
    destroyOnClose: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.detail)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.detail), 1))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.hideCance)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              class: "flex-1 border-none cancel baseBtn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onHandle('cance')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnCanceName), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.hideCance)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 1,
              class: "flex-1 rou btnSure baseBtn",
              type: "primary",
              loading: _ctx.loading,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onHandle('complete')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnCompleteName), 1)
              ]),
              _: 1
            }, 8, ["loading"]))
          : (_openBlock(), _createBlock(_component_a_button, {
              key: 2,
              class: "flex-1 btnSure-2 baseBtn",
              loading: _ctx.loading,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onHandle('complete')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnCompleteName), 1)
              ]),
              _: 1
            }, 8, ["loading"]))
      ])
    ]),
    _: 3
  }, 8, ["visible", "width", "closable", "maskClosable"]))
}