import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import home from "@/views/index.vue";
import login from "./modules/login";
import chooseMT from './modules/chooseMT'
// 根路由信息
const routes: RouteRecordRaw = {
  path: "/",
  name: "index",
  component: home,
  children: [],
};

const loginRoutes: RouteRecordRaw = {
  path: "/login",
  name: "login",
  children: [login],
};

// 导出路由
const router = createRouter({
  history: createWebHashHistory(),
  routes: [routes, loginRoutes, chooseMT],
});


//打印路由表

export default router;
