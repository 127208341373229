import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28e4d16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabBar-main" }
const _hoisted_2 = { class: "menu flex items-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "menu-item flex items-center" }
const _hoisted_5 = {
  key: 0,
  class: "tabline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          onClick: ($event: any) => (_ctx.switchRadio(item)),
          style: {"cursor":"pointer"}
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "menu-item-text",
              style: _normalizeStyle({ '--textColor': item.value == _ctx.chooseTags ? '#ffffff' : 'rgba(159, 165, 179, .7)' })
            }, _toDisplayString(item.name), 5),
            (index != _ctx.tagsData.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ activeLine: item.value == _ctx.chooseTags })
          }, null, 2)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}