import { RouteRecordRaw } from "vue-router";
import chooseMT from "@/views/chooseMT/index.vue";

export const route: RouteRecordRaw = {
    path: "/chooseMT",
    name: "chooseMT",
    component: chooseMT,
    meta: { title: "选择班组和机台", icon: "tt" },

};

export default route;
