import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80fcd0a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-screen w-full main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chooseEquipment = _resolveComponent("chooseEquipment")!
  const _component_chooseTeam = _resolveComponent("chooseTeam")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chooseEquipmentStatu)
      ? (_openBlock(), _createBlock(_component_chooseEquipment, {
          key: 0,
          onCancelClick: _ctx.cancelClick,
          onChooseClick: _ctx.chooseClick
        }, null, 8, ["onCancelClick", "onChooseClick"]))
      : (_openBlock(), _createBlock(_component_chooseTeam, {
          key: 1,
          onBackClick: _ctx.backClick
        }, null, 8, ["onBackClick"]))
  ]))
}