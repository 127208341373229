// 配置文件专用的常量定义，用于全局初始化做些内容

import { IGetMachineTeamGetMachineBindListVo, IGetMachineTeamGetSimpleTeamUserListVo } from "@/api/peopleTeam";
import { storage } from "@/views/chooseMT/constant/index";
import router from "@/router/index";
import MqttAPI, { IGetMachineProductionGetMqttConnectInfoVo } from "@/api/machine";
//获取状态信息状态名称
export const getStatusName = (state: string) => {
  let stateName = "";
  switch (state) {
    case "wait_distributed":
      stateName = "未下发";
      break;
    case "wait_produced":
      stateName = "待生产";
      break;
    case "producing":
      stateName = "生产中";
      break;
    case "incomplete":
      stateName = "未完成";
      break;
    case "completed":
      stateName = "已完成";
      break;
    case "closed":
      stateName = "已关闭";
      break;

    default:
      break;
  }
  return stateName;
};



//获取日期
export function GetDateStr(AddDayCount) {
  const dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1; //获取当前月份的日期
  const d = dd.getDate();
  return `${y}-${compareNumber(m)}-${compareNumber(d)}`;
}
//获取本周起始时间和结束时间
/********
 * type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
 */
export function getWekday(type, dates) {
  // 新的获取周的起止时间
  const now = new Date();
  const nowTime = now.getTime();
  const day = now.getDay();
  const longTime = 24 * 60 * 60 * 1000;
  const n = longTime * 7 * (dates || 0);
  let dd = null;
  if (type == "s") {
    dd = nowTime - (day - 1) * longTime + n;
  }
  if (type == "e") {
    dd = nowTime + (7 - day) * longTime + n;
  }
  dd = new Date(dd);
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1;
  const d = dd.getDate();
  const days = `${y}-${compareNumber(m)}-${compareNumber(d)}`
  return (type == 's' ? (days + " 00:00:00") : (days + " 23:59:59"));
}
//获取本月开始时间
export function getMonthStar() {
  const dd = new Date();
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1; //获取当前月份的日期
  return `${y}-${compareNumber(m)}-${'01'} 00:00:00`;
}

//获取零点时间
export function GetDateTimeZeroStr(AddDayCount) {
  return GetDateStr(AddDayCount) + " 00:00:00";
}

//获取最后一秒日期
export function GetDateTimeLastStr(AddDayCount) {
  return GetDateStr(AddDayCount) + " 23:59:59";
}

//补零
export function compareNumber(num) {
  return num < 10 ? "0" + num : num;
}

//获取状态颜色
export const getStatusColor = (state: string) => {
  let stateName = "";
  switch (state) {
    case "wait_distributed":
      stateName = "rgba(94, 94, 94, 1)";
      break;
    case "wait_produced":
      stateName = "rgba(237, 123, 47, 1)";
      break;
    case "producing":
      stateName = "rgba(19, 194, 194, 1)";
      break;
    case "incomplete":
      stateName = "rgba(227, 77, 89, 1)";
      break;
    case "completed":
      stateName = "rgba(19, 194, 194, 0.5)";
      break;
    case "closed":
      stateName = "rgba(94, 94, 94, 1)";
      break;

    default:
      break;
  }
  return stateName;
};

/**
 * 获取当前绑定的机台id
 */
export const getMachineId = (): IGetMachineTeamGetMachineBindListVo => {
  return storage.getMachineId<IGetMachineTeamGetMachineBindListVo>("machineId");
};

export const getTeamId = (): IGetMachineTeamGetSimpleTeamUserListVo => {
  return storage.getMachineId<IGetMachineTeamGetSimpleTeamUserListVo>("teamId");
};
/**
 * 获取mqtt数据
 */
export const getMqtt = (): IGetMachineProductionGetMqttConnectInfoVo => {
  return storage.getMqttData<IGetMachineProductionGetMqttConnectInfoVo>('mqtt');
}
/**
 * 获取mqtt数据
 */
export function getMqttConnectInfor() {
  MqttAPI.getMachineProductionGetMqttConnectInfo().then((res) => {
    storage.set('mqtt', res)
  }).catch(err => {
    // localStorage.removeItem('mqtt')
    return true
  })
}
//退出登陆
export const logout = () => {
  setTimeout(() => {
    router.push({ path: "/login" });
  }, 1000)
}
//进入主页
export const intoMain = () => {
  setTimeout(() => {
    router.push({ path: "/" });
  }, 1000)
}
//表格columns自定义宽度适配
export const px2rem = (px) => {
  if (/%/ig.test(px)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  } else {
    return (parseFloat(px) / 16) + 'rem'
  }
}

