
import { defineComponent } from "vue";
export default defineComponent({
    components: {},
    props: {
        data: {
            required: true,
            type: Object,
        },

    },
    setup(props, ctx) {
        const handleClick = (e, value) => {
            if (e.target.tagName !== 'INPUT') {
                return;
            } else {
                value.checked = e.target.checked
                ctx.emit('handleChangeClick', value)
            }
        }
        return {
            handleClick
        }
    }

});
