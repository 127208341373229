
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
});
