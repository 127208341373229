
import { defineComponent, reactive, ref } from 'vue';
import API, { IModifyTicketBo } from "@/api/login/index";
import { message } from "ant-design-vue";
import type { FormInstance } from 'ant-design-vue';
import type { Rule } from 'ant-design-vue/es/form';
import { Md5 } from "ts-md5/dist/esm/md5";
import { string } from 'vue-types';
export default defineComponent({
  name: 'login',
  emits: ["ckickData"],
  props: {
    code: {
      type: string,
      required: true,
    }
  },
  setup(props, ctx) {
    const formRef = ref<FormInstance>();
    const formState = reactive({
      password: "",
      surePassword: '',

    });
    const code = ref<any>(props.code)
    let validatePass = async (_rule: Rule, value: string) => {
      if (value === '') {
        return Promise.reject('请输入新密码');
      } else {
        return Promise.resolve();
      }
    };
    let validateAgainPass = async (_rule: Rule, value: string) => {
      if (value === '') {
        return Promise.reject('请再次确认密码');
      } else if (value !== formState.password) {
        return Promise.reject("两次密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    const rules: Record<string, Rule[]> = {
      password: [{ required: true, validator: validatePass, trigger: 'change' }],
      surePassword: [{ validator: validateAgainPass, trigger: 'blur' }],
    };
    const cancle = () => {
      formRef.value.resetFields();
      const value = ref('login')
      ctx.emit('ckickData', { name: 'login', code: '' })

    }
    const onFinish = (values: any) => {
      API.modPwd({ modifyTicket: code.value, password: Md5.hashStr(formState.password) }).then(
        (res) => {
          message.success({
            content: "密码修改成功",
          });
          setTimeout(() => {
            const value = ref('login')
            ctx.emit('ckickData', {name:'login',code:''})
          }, 1000);
        }, (error) => {
          formRef.value.resetFields();
          setTimeout(() => {
            ctx.emit("ckickData", 'code');
          }, 1000)
        }
      )
    }
    return {
      formState,
      rules,
      onFinish,
      formRef,
      cancle
    };
  },
});

