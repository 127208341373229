import { createStore } from "vuex";
import menu, { menuState } from "./modules/menu";
import keepAlive, { keepAliveState } from "./modules/keepAlive";
import inforReport,{keepInforState} from "./modules/inforReport"
export interface AllState {
  menu: menuState;
  keepAlive: keepAliveState;
  inforReport:keepInforState;
}

export default createStore<AllState>({
  mutations: {},
  actions: {},
  modules: { menu, keepAlive,inforReport },
});
// export interface AllState {
//   token: any;
//   user: UserState;
//   // keepAlive: keepAliveState;
// }
// export default createStore<AllState>({
//   mutations: {},
//   actions: {},
//   modules: { user },
// });

