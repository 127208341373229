
import { defineComponent, PropType, watch, ref } from "vue";
import JIcon from "@/components/jicon/index.vue"
export interface btnModel {
    id: number;
    name: string;
    status: string;
    operationTime: string;
    code: number;
}
export default defineComponent({
    components: { JIcon },
    props: {
        buttonData: {
            required: true,
            type: Object as PropType<btnModel[]>,
        },
        icon: {
            required: false,
            type: String,
        },
        orderStatu: {
            required: true,
            type: Boolean,
        },
        //是否是查看完成订单
        completeOrder: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    emits: {
        //配置字段回调
        onClick: (cell: btnModel) => {
            return true;
        }
    },
    setup(props: any, ctx: any) {
        const iconShow = ref<boolean>(true)
        let btnData = ref({}) as any
        watch(() => props.buttonData, newVal => {
            newVal.map(e => {
                if (e.status == "wait") {
                    btnData.value = e
                }
            })

        }, {
            deep: true,
            immediate: true
        })
        //-----------------变量定义-------------------
        //-----------------方法定义-------------------


        const onClick = () => {
            //只有一种情况可以触发这个数据返回
            if (props.orderStatu && !props.completeOrder) {
                ctx.emit("onClick", btnData.value)
            } else {
                ctx.emit("backOrder", true)
            }
        }
        //-----------------选择导出方法或变量-------------------
        return {
            onClick,
            btnData,
            iconShow
        };
    },
});
