import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-039dbce6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn",
    disabled: _ctx.disable,
    style: _normalizeStyle({
        background: _ctx.bgColor,
        color: _ctx.textColor,
        marginRight: _ctx.$px2rem( _ctx.marginRight + 'px'),
        opacity: _ctx.disable ? 0.4 : 1
    })
  }, _toDisplayString(_ctx.name), 13, _hoisted_1))
}