import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFont = _resolveComponent("IconFont")!

  return (_openBlock(), _createElementBlock("div", {
    class: "j-icon",
    onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.leave && _ctx.leave(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_IconFont, {
          key: 0,
          type: _ctx.icon
        }, null, 8, ["type"]))
      : _createCommentVNode("", true)
  ], 32))
}