
import { ref, defineComponent, PropType, watch, onMounted } from "vue";
export interface ITagsData {
    //标签名称
    name: string;
    //标志值
    value: string;
}

export default defineComponent({
    components: {},
    props: {
        //默认选中的标签值
        defaultTag: {
            required: false,
            type: String,
        },
        //标签数据
        tagsData: {
            required: true,
            type: Array as PropType<ITagsData[]>,
        },
        //选中值
        value: {
            required: false,
            type: String,
        },
    },
    //   },
    emits: {
        switchTags: (page: ITagsData) => {
            return true;
        },
    },
    watch: {
        value(newName, oldName) {
            this.chooseTags = newName
        },
    },
    setup(props: any, ctx: any) {
        //-----------------变量定义-------------------
        const chooseTags = ref(props.defaultTag);
        //-----------------变量方法-------------------

        const switchRadio = (item) => {
            chooseTags.value = item.value;
            ctx.emit("switchTags", chooseTags);
        };
        //-----------------选择导出方法或变量-------------------
        return { chooseTags, switchRadio };
    },
});
