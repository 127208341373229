

import { defineComponent, reactive, ref, onMounted, onBeforeUnmount, toRefs, watch, getCurrentInstance } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import API from "@/api/peopleTeam/index.api"
import mittBus from '../constant/Bus'
import JWaitCheck from "../components/JWaitCheck.vue"
import JWait from "../components/JWait.vue";
import { storage } from "../constant/index"
export default defineComponent({
    components: { SearchOutlined, JWaitCheck, JWait },
    props: {
        onWorkBtn: {
            type: Boolean,
            default: false
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance()
        const activeKey = ref<any[]>([])
        const searchPeople = ref('')
        const btnState = ref<boolean>(true)
        const listData = ([]) as any
        //存放多个班组同个人，已点击的人为准
        const clickData = ref([])
        const lockLocationIcon = ref(storage.get('setTop') ? storage.get('setTop') : '')
        const state = reactive({
            plainOptions: [] as any[],
            fiestChecked: [] as any[], //记录不同班组相同人员首次选择
        });
        watch(searchPeople, val => {
            //过滤出包含搜索字段的班组员工
            const NewArr = ref(JSON.parse(JSON.stringify(listData.value)))
            state.plainOptions = NewArr.value.map((ele: { machineTeamUser: any[]; }) => {
                ele.machineTeamUser = ele.machineTeamUser.filter(v => {
                    return v.employeesNmae.indexOf(val) !== -1
                })
                return ele
            });
            //搜索后过滤掉无员工班组
            state.plainOptions = state.plainOptions.filter(t => {
                return t.machineTeamUser.length > 0
            })
            //搜索到员工班组并展开
            activeKey.value = []
            state.plainOptions.forEach((item, index) => {
                activeKey.value.push(index + 1)
            })
        });
        watch(() => props.onWorkBtn, val => {
            clickData.value = []
            if (!val && !searchPeople.value) {
                getAllTeamList()
            }
        });
        watch(() => state.fiestChecked, val => {
            mittBus.emit('onWorkChoose', val)
            ctx.emit('onWorkChooseList', val)

        }, {
            deep: true
        }
        )
        watch(
            () => state.plainOptions,
            val => {
                val.forEach((ele, index) => {
                    const NewArr = ele.machineTeamUser.filter(item => {
                        return item.isWorking == 'n'
                    })
                    if (NewArr.length < 1) {
                        ele.disabled = true
                    }
                    ele.checked = NewArr.every(t => {
                        return t.isWorking == "n" && t.checked
                    })
                })
            },
            {
                deep: true
            }
        );
        //全选
        const onCheckAllChange = (e: any, index: number) => {
            if (e.target.checked) {
                state.plainOptions[index].machineTeamUser.map(item => {
                    if (item.isWorking == 'n') {
                        item.checked = true
                        //判断首次选中人员列表中是否已经包含了所选择的人员
                        if (!state.fiestChecked.some(el => el.employeesId == item.employeesId)) {
                            state.fiestChecked.push(item)
                        }
                        //选中其他班组中存在的全选班组中的相同人员
                        state.plainOptions.map(ele => {
                            ele.machineTeamUser.map(t => {
                                if (t.isWorking == "n" && item.employeesId == t.employeesId) t.checked = true
                            })
                        })
                    }


                })
            } else {
                state.plainOptions[index].machineTeamUser.map(item => {
                    if (item.isWorking == 'n') {
                        item.checked = false
                        //将取消的人员从首次选中的人员移除
                        state.fiestChecked.map((el, index) => {
                            if (el.employeesId == item.employeesId) {
                                state.fiestChecked.splice(index, 1)
                            }
                        })
                        //移除其他班组中含有取消全选班组中人员
                        state.plainOptions.map(ele => {
                            ele.machineTeamUser.map(t => {
                                if (t.isWorking == "n" && item.employeesId == t.employeesId) t.checked = false
                            })
                        })
                    }

                })

            }

        }
        //单人员选择           
        const handleChangeClick = (data) => {
            if (data.checked && !state.fiestChecked.some(el => el.employeesId == data.employeesId)) {
                state.fiestChecked.push(data)
            } else {
                state.fiestChecked.map((el, index) => {
                    if (el.employeesId == data.employeesId) {
                        state.fiestChecked.splice(index, 1)
                    }
                })
            }
            //将不同班组相同人员状态同步
            state.plainOptions.map(item => {
                item.machineTeamUser.map(t => {
                    if (t.isWorking == 'n' && t.employeesId == data.employeesId) {
                        t.checked = data.checked
                    }
                })
            })
        }
        const getAllTeamList = () => {
            state.fiestChecked = []
            API.getMachineTeamGetTeamAndUser().then((res) => {
                listData.value = res.list
                res.list.map(item => {
                    item['checked'] = false
                    item['disabled'] = false
                    item.machineTeamUser.map(v => {
                        if (v.isWorking == 'y') {
                            v['checked'] = true
                        } else {
                            v['checked'] = false
                        }
                    })
                })
                state.plainOptions = res.list
            })
        }

        //阻止点击全选时折叠面板的打开或关闭
        const handleClick = (event: MouseEvent) => {
            event.stopPropagation();
        };
        onMounted(() => {
            getAllTeamList()
            mittBus.on('onWorkCancle', (data: any) => {
                //取消选择后，待上机取消选择
                state.plainOptions.map(item => {
                    item.machineTeamUser.map(t => {
                        if (t.employeesId == data.employeesId && t.isWorking == 'n') {
                            t.checked = false
                        }
                    })
                })
                //取消首次选择的人员
                state.fiestChecked.map((item, index) => {
                    if (item.employeesId == data.employeesId) {
                        state.fiestChecked.splice(index, 1)
                    }
                })
            })
            mittBus.on('updataDate', (data: boolean) => {
                getAllTeamList()
            })
        })
        onBeforeUnmount(() => {
            // 移除全部事件
            mittBus.all.clear()
        })
        return {
            state,
            activeKey,
            btnState,
            listData,
            lockLocationIcon,
            searchPeople,
            // KeyDown,
            ...toRefs(state),
            onCheckAllChange,
            handleChangeClick,
            focus,
            blur,
            getAllTeamList,
            handleClick,
        }
    }
})

