import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51732e4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "NameText" }
const _hoisted_2 = { class: "flex-1 flex items-center col-text" }
const _hoisted_3 = { class: "w-3/5 employeesCode-ellipsis text-center" }
const _hoisted_4 = { class: "w-2/5 position-ellipsis text-center" }
const _hoisted_5 = { class: "achievements" }
const _hoisted_6 = { class: "text-center numPrecent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_checkbox, { value: _ctx.data }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data.employeesName), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data.employeesCode), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.position), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.data.coefficient && Number(_ctx.data.coefficient) > 0 ? _ctx.data.coefficient + '%' : 0 + '%'), 1)
          ])
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }))
}