import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-945d1f18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex text-xl justify-between items-center mainText" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "center" }
const _hoisted_4 = { class: "text-center numCount" }
const _hoisted_5 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!

  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", {
        class: "icon-button",
        onTouchstart: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'reduce')), ["prevent"])),
        onTouchmove: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
        onTouchend: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'reduce')), ["prevent"]))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_minus_outlined, { class: "icon" })
        ])
      ], 32),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.count), 1),
      _createElementVNode("span", {
        class: "icon-button",
        onTouchstart: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'increase')), ["prevent"])),
        onTouchmove: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
        onTouchend: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'increase')), ["prevent"]))
      }, [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_plus_outlined, { class: "icon" })
        ])
      ], 32)
    ])
  ]))
}