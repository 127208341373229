
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import JTabs, { ITagsData } from "@/components/jtabs-1/index.vue";
import API from "@/api/login/index";
import { message } from "ant-design-vue";
import router from "@/router/index";
import { getMachineId, getTeamId } from "@/constant/index"
import { logout } from "@/constant/index";
import JIcon from "@/components/jicon/index.vue"
import jtabBar from "@/components/jtabs-1/jtabBar.vue";
import { mqttStatu } from "@/utils/mqtt";
import title1 from "./title1.vue"
export default defineComponent({
  components: { jtabBar, JIcon, title1 },
  emits: {
    switchPage: (page: string, parm: any) => {
      return true;
    },
  },
  props: {
    option: {
      required: false,
      type: String,
    },
  },
  watch: {
    option(newName, oldName) {
      this.rValue = newName
    }
  },
  setup(props: any, ctx: any) {
    //获取当前时间
    let dataTime = ref("");
    //机台名
    const machineName = ref(getMachineId() ? getMachineId().machineName : '')
    //班组名
    const teamName = ref(getTeamId() ? getTeamId().teamName : '')
    let defaultTag = ref(localStorage.getItem('pageName') ? localStorage.getItem('pageName') : 'peopleTeam')
    //信号状态
    const WIFIStatu = ref('')
    //设备mqtt链接状态
    const statu = ref<boolean>(false)
    let tagsTag: any = ref([
      { name: "员工班组", value: "peopleTeam" },
      { name: "工单任务", value: "workOrder" },
      { name: "生产详情", value: "machine" },
      { name: "工作记录", value: "reportRecord" },

    ]);
    watch(mqttStatu, (newVal, oldVal) => {
      statu.value = newVal
    }, {
      deep: true,
      immediate: true
    }
    )
    let rValue = ref('');
    let timer = ref()
    let wifiTimer = ref()

    const switchRadio = (page: ITagsData) => {
      //切换页面按钮
      ctx.emit("switchPage", page.value, "");
    };
    //刷新当前页面
    const refresh = () => {
      //@ts-ignore: 过滤报错  刷新接口
      window.mes.reload()
    }
    // 当前时间
    const getNowTime = () => {
      let now = new Date();
      let month =
        now.getMonth() >= 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      let date = now.getDate() >= 10 ? now.getDate() : `0${now.getDate()}`;
      let hour = now.getHours() >= 10 ? now.getHours() : `0${now.getHours()}`;
      let minutes =
        now.getMinutes() >= 10 ? now.getMinutes() : `0${now.getMinutes()}`;
      let seconds =
        now.getSeconds() >= 10 ? now.getSeconds() : `0${now.getSeconds()}`;
      dataTime.value = `${month}月${date}日 ${hour}:${minutes}:${seconds}`;
    };
    const changeMT = (data) => {
      switch (data) {
        case "Machine":
          router.push({ path: "/chooseMT", query: { key: 1 } });
          break;
        case "team":
          router.push({ path: "/chooseMT", query: { key: 2 } });
          break;
        case "logout":
          API.logout().then((res) => {
            message.success({ content: "退出登录成功", });
            logout()
          })
          break;
        default:
          break;
      }
    }
    //底部显示与隐藏
    const showButtom = () => {
      //@ts-ignore: 过滤报错  
      window.mes.setVisibility()
    }
    const signal = () => {
      let state = '';
      try {
        //@ts-ignore: 过滤报错  信号接口
        state = window.mes.getNetRssi()
      } catch (error) {
        state = 'worst';
      }
      WIFIStatu.value = state
    }
    onMounted(() => {
      getNowTime()
      timer.value = setInterval(getNowTime, 1000);
      wifiTimer.value = setInterval(signal, 10 * 1000)
    });
    onBeforeUnmount(() => {
      clearInterval(timer.value)
      clearInterval(wifiTimer.value)
      timer.value = null;
      wifiTimer.value = null;
    })
    return {
      dataTime,
      machineName,
      teamName,
      WIFIStatu,
      statu,
      switchRadio,
      changeMT,
      signal,
      showButtom,
      tagsTag,
      defaultTag,
      rValue,
      refresh
    };
  },
});
