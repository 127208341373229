import { get, put, post, del, page } from "@/api/service";
import * as bo from "./index.type.bo";
import * as vo from "./index.type.vo";

const baseUrl = "djmes/messerver";


export default {
  /**
   * 获取当前机台工单上报记录-/machine/uploadRecord/getUpLoadRecord
   * @param bo 传入参数
   * @returns 
   */
  postMachineUploadRecordGetUpLoadRecord(bo: bo.IPostMachineUploadRecordGetUpLoadRecordBo) {
    return post<page<vo.IPostMachineUploadRecordGetUpLoadRecordVo>>(baseUrl + "/machine/uploadRecord/getUpLoadRecord", bo);
  },
  /**
   * 获取当前机台工单上报记录的统计信息-/machine/uploadRecord/getUpLoadRecordCount
   * @param bo 传入参数
   * @returns 
   */
  postMachineUploadRecordGetUpLoadRecordCount(bo: bo.IPostMachineUploadRecordGetUpLoadRecordCountBo) {
    return post<vo.IPostMachineUploadRecordGetUpLoadRecordCountVo>(baseUrl + "/machine/uploadRecord/getUpLoadRecordCount", bo);
  },
};