
import { defineComponent, reactive, ref, watch } from "vue";
import router from "@/router/index";
import API, { ILoginAppParmsBo } from "@/api/login/index";
import { message } from "ant-design-vue";
import { Md5 } from "ts-md5/dist/esm/md5";
import { storage } from "@/views/chooseMT/constant";

//---------------------传入参数、回调、监听定义---------------------
//定义回调事件
export default defineComponent({
  emits: ["ckickData"],
  setup(props, ctx) {
    const formRef = ref();
    let loading = ref<boolean>(false)
    const formState = ref<ILoginAppParmsBo>({
      userName: '',
      passWord: '',
      appName: ''
    });
    watch(() => formState.value.userName, newVal => {
      if (newVal.length > 11) {
        formState.value.userName = newVal.substring(0, 11);
      }
    })
    const rules = {
      userName: [
        { required: true, message: "请输账号", trigger: "blur" },
      ],
      passWord: [
        { required: true, message: "请输入邮箱", trigger: "blur" },
      ],
    }
    const forgetPass = () => {
      ctx.emit("ckickData", { name: 'code', code: '' });
    };
    const onFinish = (values: any) => {

      loading.value = true
      API.appLogin({ userName: formState.value.userName, passWord: Md5.hashStr(formState.value.passWord), appName: 'DJMES' }).then(
        (res: any) => {
          message.success({
            content: "登录成功，即将前往首页!",
            key: "login",
          });

          if (localStorage.getItem('userId') && localStorage.getItem('userId') != res.userId) {
            storage.remove('teamId')
            storage.remove('machineId')
          }
          localStorage.setItem('pageName', 'peopleTeam')
          localStorage.setItem('userId', res.userId)
          setTimeout(() => {
            router.push({ path: "/chooseMT" });
          }, 1000);
        }, (err) => {
          setTimeout(() => {
            loading.value = false
          }, 1000);
        }
      );
    };
    return {
      formState,
      formRef,
      rules,
      loading,
      forgetPass,
      onFinish,
    };
  },
});
