import { RouteRecordRaw } from "vue-router";
import login from "@/views/login/index.vue";

export const route: RouteRecordRaw = {
  path: "",
  name: "登录",
  component: login,
  meta: { title: "登录页面", icon: "tt" },

};

export default route;
