import { get, put, post, del, page } from "@/api/service";
import * as bo from "./index.type.bo";
import * as vo from "./index.type.vo";

const baseUrl = "djmes/messerver";


export default {
  /**
   * 完工-结束任务-/machine/workOrderTask/endTask
   * @param bo 传入参数
   * @returns 
   */
  postMachineWorkOrderTaskEndTask(bo: bo.IPostMachineWorkOrderTaskEndTaskBo) {
    return post(baseUrl + "/machine/workOrderTask/endTask", bo);
  },
  /**
   * 获取当前机台工单-/machine/workOrderTask/getCurrMachineWorkOrderList
   * @param bo 传入参数
   * @returns 
   */
  postMachineWorkOrderTaskGetCurrMachineWorkOrderList(bo: bo.IPostMachineWorkOrderTaskGetCurrMachineWorkOrderListBo) {
    return post<page<vo.IPostMachineWorkOrderTaskGetCurrMachineWorkOrderListVo>>(baseUrl + "/machine/workOrderTask/getCurrMachineWorkOrderList", bo);
  },
  /**
   * 获取当前工序工单-/machine/workOrderTask/getProcessWorkOrderList
   * @param bo 传入参数
   * @returns 
   */
  postMachineWorkOrderTaskGetProcessWorkOrderList(bo: bo.IPostMachineWorkOrderTaskGetProcessWorkOrderListBo) {
    return post<page<vo.IPostMachineWorkOrderTaskGetProcessWorkOrderListVo>>(baseUrl + "/machine/workOrderTask/getProcessWorkOrderList", bo);
  },
  /**
   * 认领工单-/machine/workOrderTask/receivedWorkOrder
   * @param bo 传入参数
   * @returns 
   */
  postMachineWorkOrderTaskReceivedWorkOrder(bo: bo.IPostMachineWorkOrderTaskReceivedWorkOrderBo) {
    return post(baseUrl + "/machine/workOrderTask/receivedWorkOrder", bo);
  },
};