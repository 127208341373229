

import { defineComponent, reactive, ref } from 'vue';
import LoginForm from './page/loginForm.vue';
import ForgetPass from "./page/forgetPass.vue";
import phoneCode from "./page/phoneCode.vue"

export default defineComponent({
  name: 'login',
  components: {
    LoginForm,
    ForgetPass,
    phoneCode
  },
  setup() {
    const state = reactive({
      loginState: 'login',
      code: ''
    });
    const loginState = ref<boolean>(false)
    const ckickData = (value: any) => {
      state.loginState = value.name
      state.code = value.code
    };
    return {
      state,
      loginState,
      ckickData,
    }
  },

});
