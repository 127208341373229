/*
 * @Name:
 * @Descripttion:
 * @version: v1.0.1
 * @Author: jfy
 * @Date: 2022-05-10 17:22:18
 * @LastEditors  : jfy
 * @LastEditTime : 2022-05-16 16:27:39
 */
import { AxiosRequestConfig } from 'axios';

const pending: any[] = []; //用于存储每个ajax请求的取消函数和ajax标识
const task: Map<string | number, any> = new Map(); //用于存储每个ajax请求的处理函数，通过请求结果调用，以ajax标识为key

//请求开始前推入pending
export const pushPending = (key: string, value: any) => {
  pending.push({ key: key, value: value });
};

//请求完成后取消该请求，从列表删除
export const removePending = (config: AxiosRequestConfig) => {
  const key = config.url ? config.method + ':' + config.url : '';
  for (let index = 0; index < pending.length; index++) {
    const element = pending[index];
    if (element.key === key) {
      //当前请求在列表中存在时
      pending.splice(index, 1); //把这条记录从列表中移除
      element.value(); //执行取消操作
    }
  }
};

// 创建task
export const createTask = (key: string | number, reject: (arg0: any) => boolean | void) => {
  const callback = (parm: handleEven) => {
    // 这里处理授权逻辑  处理全局异常
    if (parm.status && parm.status === 200 && (parm.code == '10002200'||parm.code=='100057')) {
      window.location.href = '#/login';
    } else if (parm.status) {
      reject(parm);
    }
  };
  if (!task.get(key)) {
    task.set(key, []);
  }
  task.set(key, callback);
};
// 处理task
export const handleTask = (key: string | number, parm: handleEven) => {
  if (task.get(key)) {
    task.get(key)(parm);
  }
  task.set(key, undefined);
};

export  interface handleEven {
  status?: number;
  success: boolean;
  code: string;
  msg: string;
}
