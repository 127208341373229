//例子
// export const exm:string="test";

export const storage = {
  //存储 key(machineId:选择的机台数据，teamId：选择的班组数据 )
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  //取出机台数据
  getMachineId<T>(machineId: string) {
    const value = localStorage.getItem(machineId);
    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }
  },
  //取出班组数据
  getTeamId<T>(teamId: string) {
    const value = localStorage.getItem(teamId);
    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }
  },
  //暂时缓存需要切换的班组
  getNewMachineId<T>(newMachineId: string) {
    const value = localStorage.getItem(newMachineId);
    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }
  },
  //取出mqtt数据
  getMqttData<T>(mqtt: string) {
    const value = localStorage.getItem(mqtt);
    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }
  },
  // 删除数据
  remove(key: string) {
    localStorage.removeItem(key);
  },
};

