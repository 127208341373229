
import { defineComponent, reactive, ref, watch, onMounted, onBeforeUnmount, toRefs } from "vue";
import mittBus from '../constant/Bus'
export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        onWorkStatue: {
            type: Boolean,
            default: false,
        },
        offWorkStatue: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, ctx) {
        const btnState = ref<boolean>(true)
        //上机选择人员列表
        const state = reactive({
            plainOptions: [] as any,
            //默认选中
            defaultCheckedList: [] as any
        });
        watch(
            () => state.defaultCheckedList,
            val => {
                state.plainOptions = val
                state.defaultCheckedList = val
                if (props.offWorkStatue) {
                    //下机人员选择取消回传
                    mittBus.emit('offWorkCancle', val)
                }
            },
        );
        const handelChange = (item: any) => {
            if (props.onWorkStatue) {
                //上机人员选择取消回传
                mittBus.emit('onWorkCancle', item)
            }
        }
        onMounted(async () => {
            //下机人员选择列表
            mittBus.on('offWorkChoose', (data: any) => {
                state.plainOptions = data
                state.defaultCheckedList = data
            })
            //上机人员选择列表
            mittBus.on('onWorkChoose', (data: any) => {
                state.plainOptions = []
                state.defaultCheckedList = []
                state.plainOptions = data
                state.defaultCheckedList = data
            })
        })
        onBeforeUnmount(() => {
            // 移除事件
            mittBus.all.clear();
            
        })

        return {
            state,
            btnState,
            handelChange,
            ...toRefs(state),
        }
    }
})

