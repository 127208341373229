import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-327c4f60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-center" }
const _hoisted_2 = { class: "title flex justify-between items-center" }
const _hoisted_3 = { class: "explain" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-center value" }
const _hoisted_6 = { class: "center" }
const _hoisted_7 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.explain), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.showValue), 1),
      (!_ctx.disable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex flex-1 items-center justify-end",
            style: _normalizeStyle({ height: _ctx.$px2rem(' 81px') })
          }, [
            _createElementVNode("div", {
              class: "icon-button",
              onTouchstart: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'reduce')), ["prevent"])),
              onTouchmove: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              onTouchend: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'reduce')), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_minus_outlined, { class: "icon" })
              ])
            ], 32),
            _createElementVNode("div", {
              class: "icon-button increase",
              onTouchstart: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goTouch($event, 'increase')), ["prevent"])),
              onTouchmove: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Touchmove && _ctx.Touchmove(...args)), ["prevent"])),
              onTouchend: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.outTouch($event, 'increase')), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_plus_outlined, { class: "icon" })
              ])
            ], 32)
          ], 4))
        : _createCommentVNode("", true)
    ])
  ]))
}