
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from "vue";
import { ITableData } from "./tableType";
import { px2rem } from "@/constant";
const __default__ = defineComponent({
  components: {},
  props: {
    columns: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Object as PropType<ITableData<any>>,
    },
    curPage: {
      required: true,
      type: Number,
    },
    pageSize: {
      required: false,
      type: Number,
      default: 20,
    },
    //进度条位置
    paginationPosition: {
      required: false,
      type: String,
      default: "bottomRight",
    },
    //表单高度偏移量
    tableHeightOffset: {
      required: false,
      type: Number,
      default: 0,
    },
    rowSelection: {
      required: false,
    }
  },
  emits: {
    updateData: (current: number, pageSize: number) => {
      return true;
    },
  },
  setup(props: any, ctx: any) {
    let palnlist = ref<any>(null);

    const clienHeight = ref<number>(0);
    //table初始化的标准高度
    const tablesTandardHeight = ref<number>(0);
    const scrollX = ref<string>("max-content");
    const scrollY = ref<number>(0);
    const emptyH = ref<string>("auto");

    //表格 分页器属性
    const paginationPage = ref({
      position: [props.paginationPosition],
      current: 1,
      pageSize: props.pageSize, // 默认每页显示数量
      showSizeChanger: true, // 显示可改变每页数量
      pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
      total: props.data.total > 0 ? props.data.total : 0.1,
      showTotal: (total: any) => {
        if (total === 0.1) {
          //#TODO 解决这个奇怪的bug,等于0会丢失
          return `共 0 条`;
        }
        return `共 ${total} 条`;
      }, // 显示总数
      hideOnSinglePage: false,
    });

    //分页事件
    function pageChange(pagination: any, filters: any, sorter: any) {
      paginationPage.value.pageSize = pagination.pageSize;
      paginationPage.value.current = pagination.current < 1 ? 1 : pagination.current;
      ctx.emit(
        "updateData",
        paginationPage.value.current,
        paginationPage.value.pageSize
      );
    }

    function getTableHeight() {
      if (palnlist.value) {
        if (scrollY.value <= 0) {
          scrollY.value = palnlist.value.clientHeight - 64 - 90;
          tablesTandardHeight.value = scrollY.value;
        } else {
          let tempHeight =
            tablesTandardHeight.value -
            (clienHeight.value - document.body.clientHeight);
          scrollY.value = tempHeight >= 32 ? tempHeight : 32;
        }
        emptyH.value = scrollY.value - 32 + "px";
      }
    }

    const caclScrollX = () => {
      let scX = 0;
      for (let index = 0; index < props.columns.length; index++) {
        const element = props.columns[index];
        if (element.width && !isNaN(Number(element.width.toString()))) {
        element.width=px2rem(element.width)
          scX += element.width;
        } else {
          return;
        }
      }
      scrollX.value = scX + "px";
    };
    onMounted(() => {
      window.addEventListener("resize", getTableHeight);
      clienHeight.value = document.body.clientHeight;
      getTableHeight();
      caclScrollX()
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", getTableHeight);
    }),

      //监听总数变化
      watch(
        () => props.data,
        (newVal) => {
          paginationPage.value.total =
            newVal.total && newVal.total > 0 ? newVal.total : 0.1;
        },
        {
          deep: true,
        }
      );
    //监听页数变化
    watch(
      () => props.curPage,
      (newVal) => {
        paginationPage.value.current = newVal > 0 ? newVal : 1;
      }
    );

    return {
      palnlist,
      scrollX,
      scrollY,
      emptyH,
      getTableHeight,
      paginationPage,
      pageChange,
      clienHeight,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "70acebfb": (_ctx.emptyH)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__